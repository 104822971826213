import $ from 'jquery'
import { } from 'jquery-ujs'
import Turbolinks from 'turbolinks'
import moment from 'moment'
import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import NProgress from 'nprogress'

import 'cocoon-js'
import 'select2'
import 'select2/select2_locale_pt-BR'
import 'bootstrap'
import 'qtip2'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min'
import 'fullcalendar'
import 'fullcalendar/dist/locale/pt-br'
import 'datatables.net'
import 'datatables.net-bs4'
import 'controllers'

Turbolinks.start()

window.moment = moment
window.NProgress = NProgress
Turbolinks.setProgressBarDelay(250)

import 'stylesheets/application'