import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['my']

  toggleNextField(event) {
    const $nextField = $(event.target).parent().parent().next()

    if ($(event.target).is(':checked')) {
      $nextField.fadeIn()
    } else {
      $nextField.fadeOut()
    }
  }

  connect() {
    console.log('START SHOW FIELD')

    const $nextField = $(this.myTarget).parent().parent().next()
    
    if ($(this.myTarget).is(':checked')) {
      $nextField.css('display','block')
    } else {
      $nextField.css('display', 'none')
    }

  }

}
