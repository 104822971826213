import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START NEW RESOURCE')
  }

  showNewResource(e) {
    e.preventDefault()
    const $nextField = $(e.target).parent().parent().next()
    $($nextField).toggle()
  }

}