import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "my" ]

  goTo(e) {
    e.preventDefault()
    const RAILS_ROOT = "https://anfiteatro.imt.usp.br"
    const urlCollection = this.data.get('collection')
    const group = $(e.target).data('group')
    const service = `${RAILS_ROOT}/${urlCollection}`
    this.mountTable(service)
  }

  mountTable(service) {
    $(this.myTarget).DataTable({
      destroy: true, //alow table be remounted
      processing: true, //utilize backend
      serverSide: true, //utilize backend
      ajax: {
        url: service
      },
      columns: [
        { width: "17%", name: 'sala' },
        { width: "20%", name: 'solicitante' },
        { width: "20%", name: 'evento' },
        { width: "17%", name: 'dt_initial' },
        { width: "17%", name: 'dt_final' },
        { width: "3%", searchable: false, orderable: false },
        { width: "3%", searchable: false, orderable: false },
        { width: "3%", searchable: false, orderable: false }
      ],
      order: [[3, 'desc']],
      pageLength: 25,
      lengthMenu: [[10,25,50,100,150,200,-1],[10,25,50,100,150,200,'Todos']],
      pagingType: "full_numbers",
      language: {
        search: "Procurar",
        lengthMenu: "Exibir _MENU_ items por página",
        zeroRecords: "Nada Encontrado",
        info: "Exibindo páginas _PAGE_ de _PAGES_",
        infoEmpty: "Nenhum item disponível",
        infoFiltered: "(filtrado _MAX_ items ao todo)",
        decimal: ",",
        thousands: ".",
        paginate: {
          first: "Primeira",
          previous: "Anterior",
          next: "Próxima",
          last: "Última"
        },
      }
    })
  }

  disconnect() {
    $(this.myTarget).DataTable().destroy();
  }

  connect() {
    console.log('START DATA TABLE')
    const RAILS_ROOT = "https://anfiteatro.imt.usp.br"
    const urlCollection = this.data.get('collection')
    const service = `${RAILS_ROOT}/${urlCollection}`
    this.mountTable(service)
  }

}