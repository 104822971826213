import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["month"]

  connect() {
    console.log('START MONTHPICKER')

    $(this.monthTarget).datepicker({
      language: "pt-BR",
      startView: "months",
      minViewMode: "months",
      format: "yyyy-mm"
    })
  }

}