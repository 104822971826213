import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = []

  connect() {
    console.log('START PROGRESS')

    let splashHtml =
    `<div class="splash card-splash">
        <div role="spinner">
            <div class="spinner-icon"></div>
        </div>
        <p class="lead" style="text-align:center">Aguarde...</p>
        <div class="progress">
            <div class="mybar" role="bar">
        </div>
        </div>
    </div>`

    NProgress.configure({
        template: splashHtml
    })

    $(document)
      .on('turbolinks:click', () => {
        NProgress.start()
      })
      .on('turbolinks:render', () => {
        NProgress.done()
        NProgress.remove()
      })
  }

}