import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START UPLOAD')
    const url = this.data.get('url')
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const url = this.data.get('url')
    const SERVER = "https://anfiteatro.imt.usp.br"
    const form = document.querySelector('.form-uploader')
    const fileInput = document.getElementById('file')
    const file = fileInput.files[0]

    if (file === undefined) {
      $('#modal-upload').before('<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Por favor, selecione um arquivo!</div>')
      return false
    }

    const formData = new FormData(form)
    formData.append('file', file)

    $.ajax({
      url: `${SERVER}${url}`,
      type: "POST",
      data: formData,
      processData: false,
      contentType: false
    })

  }
}
