import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dttime" ]

  connect() {
    console.log('START DATETIMEPICKER')

    $(this.dttimeTarget).flatpickr({
      "locale": "pt",
      static: true,
      enableTime: true,
      allowInput: true,
      time_24hr: true,
      dateFormat: "d/m/Y H:i"
    })
  }

}
