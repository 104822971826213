import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  setSala(salaId) {
    return (salaId ? `?sala_id=${salaId}` : '')
  }

  getSelectedSala(event) {
    let select = event.target
    let selected_value = $('option:selected', select).val()
    this.refetch(selected_value)
  }

  calendar(salaParam) {
    const RAILS_ROOT = "https://anfiteatro.imt.usp.br"

    $('#calendar').fullCalendar({
      defaultView: 'month',
      weekends: true,
      aspectRatio: 2,
      locale: 'pt-br',

      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,basicWeek,listWeek'
      },

      eventSources: [{
        url: `${RAILS_ROOT}/events.json${salaParam}`,
        textColor: 'white'
      }],

      views: {
        agendaWeek: { // name of view
          allDaySlot: false,
          slotLabelFormat: " ",
          slotDuration: "00:60:00",
          minTime: '07:00',
          //maxTime: '23:59'
        }
      },

      loading: function (bool) {
         if(bool){
           $("#loading-indicator").show()
         } else {
           $("#loading-indicator").hide()
         }
      },

      viewRender: function(view, element) {
        $('#sala_id').trigger('change')
      },

      eventRender: function(event, element) {
        let dt_init = moment(event.start).format('DD/MM/YYYY - HH:mm:SS')
        let dt_final = moment(event.end).format('DD/MM/YYYY - HH:mm:SS')
        let edit = ''
        let obs = ''
        let msg = ''

        if (event.edit)
          edit = `<a href="${RAILS_ROOT}/events/${event.id}/edit/" class="btn btn-sm btn-block btn-primary" data-remote="true" >EDITAR</a>`

        if (event.obs != '')
          obs = '<em>(' + event.obs + ')</em>'

        msg +=  '<div class=\'qtip-event-content card-calendar\'>'
                + `<p class=\'qtip-event-content-event-description\'>${event.description}</p>`
                + `<p class=\'qtip-event-date\'>${dt_init}</p>`
                + `<p class=\'qtip-event-date\'>${dt_final}</p>`
                + `<p>${obs}</p>`
                + edit
                + '</div>'

        $(element).qtip({
          content: {
            text: msg,
            title: {
              text: event.reserva,
              button: true
            }
          },
          show: 'mousedown',
          events: {
            show: function(event, api) {
              // Only show the tooltip if it was a right-click
              if(event.originalEvent.button !== 2) {
                event.preventDefault()
              }
            }
          },
          hide: {
            event: 'click'
          },
          style: {
            classes: 'qtip-tipped',
            width: 500,
            //height: 200
          },
          position: {
            my: 'top right',
            at: 'center bottom',
            target: $(element)
          }
        })
      },

      dayClick: function(date, jsEvent, view) {
        let url = `${RAILS_ROOT}/events/new.js?date=${date.format()}`
        $.get(url)
      },

      eventClick: function(calEvent, jsEvent, view) {
        let id = calEvent.id
        let url = `${RAILS_ROOT}/events/${id}/`

        if (calEvent.edit) { // can edit event?
          if (confirm('Tem certeza que deseja apagar essa reserva?')) {
            if (confirm('Confirme novamente a exclusão da reserva.')) {  
              $.ajax({
                url: url,
                type: "DELETE"
              });
              $('#sala_id').trigger('change')
            }
          }
        }

        return false //because calEvent has url attribute
      }
    })
  }

  refetch(sala_id) {
    const RAILS_ROOT = "https://anfiteatro.imt.usp.br"
    const $container = $('#calendar')
    let salaParam = this.setSala(sala_id)
    
    $container.fullCalendar('removeEventSources')
    $container.fullCalendar('addEventSource', `${RAILS_ROOT}/events.json${salaParam}`)
  }

  pickMonth(e) {
    const $container = $('#calendar')

    e.preventDefault()
    let $date = $('#month-pick-value').val()
    let regex = /^(19|20)\d\d-(0[1-9]|1[012])$/ //YYYY-MM

    if (regex.test($date))
      $container.fullCalendar('gotoDate', $date)
  }

  connect() {
    console.log('START CALENDAR')

    this.calendar(this.setSala(null))

    $(document).on('change keypress', '#sala_id', this.getSelectedSala.bind(this))
    $(document).on('contextmenu', function (e) { e.preventDefault(); })
    $(document).on('click', '#month-pick', this.pickMonth.bind(this))
  }

}
