import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  toggleMultiDias(event) {
    const $target = $(event.target).parent().parent().next()
    $target.fadeToggle()
  }

  connect() {
    console.log('START EVENT')

  }

}